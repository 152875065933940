

  .custom-nav-link {
    font-size: 90px; /* Adjust the font size as per your desired size */
  }
  .nav-link{
    font-family: 'Roboto', sans-serif;
    color: #00080e !important;
    
  }
  h3{
    color: rgb(255, 166, 0)
  }