.menu-center-text{
    /* align-content: center; */
    text-align: center;
}
.menu-right-text{
    text-align: end;
}

.menu-card-height{
    height: 400px;
    max-height: 400px;
}

.remove-footer-prop{
    color: default !important;
    background-color: white !important;
    border-top: none !important;
}