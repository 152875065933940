
.slideimages{
    height: 550px;
    width: 100%;
}

.public-content {
    
    width: 100%;
    padding: 100px 15px;
    margin: 0 auto;
}

  .scrolling-text {
    white-space: nowrap;
    overflow: hidden;
    animation: scroll 10s linear infinite;
  }

  /* @keyframes scroll {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(100%);
    }
  } */
